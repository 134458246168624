import React from "react";
import Altar from "./Altar";

const MusicChapel = () => {
  return (
    <div className="music-yard">
      <div className="altar-selah">Number 1 Songs</div>
      <div className="altar-base">
        <Altar artist="Chase Atlantic" song="Into it" />
        <Altar artist="Justin Bieber" song="Ghost" />
        <Altar artist="Always Never" song="Bad for me" />
        <Altar artist="Billie Eilish" song="BIRDS OF A FEATHER" />
        <Altar artist="Sabrina Carpenter" song="Don't smile" />

        <Altar artist="Chase Atlantic" song="LOVE IS ( NOT ) EASY" />
        <Altar artist="Ikkimel, Barré & GX488" song="WELLNESS" />
        <Altar artist="Nessa Barrett" song="Passenger Princess" />
        <Altar artist="Sicily Rose" song="HoDown" />
        <Altar artist="Morgin Madison & Dominique" song="Drifter" />
        <Altar artist="Chase Atlantic" song="Molly" />
        <Altar artist="Alison Wonderland" song="New Day" />

        <Altar
          artist="Benny Benassi"
          song="Come Fly Away (Soha & Adam K Remix)"
        />
        <Altar artist="Dua Lipa" song="Dance the night" />
        <Altar artist="Bazzi" song="Fantasy" />
        <Altar artist="Julia Michaels" song="Heaven" />
        <Altar artist="Chase Atlantic" song="Paradise" />
        <Altar
          artist="Charli XCX"
          song="Unlock It (feat. Kim Petras and Jay Park)"
        />
        <Altar artist="DallasK, Nicky Romero, & XYLØ" song="Sometimes" />
      </div>
      <div className="altar-plate">
        <div className="altar-plate-sln">
          Sweet luv news <br />
        </div>
        <div className="altar-plate-lattice">&</div>

        <div className="altar-plate-jotp">Journal of the Press</div>
      </div>
    </div>
  );
};

export default MusicChapel;
